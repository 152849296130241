<template>
  <div id="userProfile">
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">My Profile</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path: '/my-profile' }">My Profile</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-col :span="24">
        <el-form v-loading="loading" element-loading-spinner="atom-audit-loader" ref="form" label-position="left" label-width="160px" size="mini">
          <el-form-item label="Disable Coder Response Emails">
            <el-switch v-model="userProfile.profile.disableCoderResponse" active-color="#13ce66" inactive-color="#ff4949" @change="validUserProfile()"></el-switch>
          </el-form-item>
        </el-form>
      </el-col>
    </el-card>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'UserProfile',
    data() {
      return {
        loading: true,
        setProfile: {
          profile: {},
          userId: 0
        }
      }
    },
    methods: {
      ...mapActions('userProfile/', ['GET_USERPROFILE', 'PUT_USERPROFILE', 'POST_USERPROFILE']),
      initialize() {
        const payload = {
          userId: this.currentTenantUserId
        }
        this.loading = true
        Promise.all([this.GET_USERPROFILE(payload)]).then(response => {
          this.loading = false
        })
        this.loading = false
      },
      async validUserProfile() {
        try {
          if (this.userProfile.userId === null) {
            // post user profile
            this.setProfile.profile = JSON.stringify(this.userProfile.profile)
            const payload = {
              userId: this.currentTenantUserId,
              profile: this.setProfile.profile
            }
            this.loading = true
            await this.POST_USERPROFILE(payload)
            this.loading = false
          } else {
            // put user profile
            this.setProfile.userId = this.userProfile.userId
            this.setProfile.userProfileId = this.userProfile.userProfileId
            this.setProfile.profile = JSON.stringify(this.userProfile.profile)
            this.loading = true
            await this.PUT_USERPROFILE(this.setProfile)
            this.loading = false
          }
          this.$message({
            message: 'Profile Update Successful',
            type: 'success'
          })
        } catch (err) {
          console.log(err)
          this.loading = false
          this.$message({
            message: 'Reason for Change Added Error',
            type: 'error'
          })
        }
      }
    },
    created: function () {
      this.initialize()
    },
    computed: {
      ...mapState('userProfile/', ['userProfile']),
      ...mapState('user/', ['currentTenantUserId'])
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
